// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Url from "../../../../utils/Url.res.js";
import * as Link from "../../../../styleguide/components/Link/Link.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Routes_BlogPost from "../../../../routes/common/Routes_BlogPost.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as BlogPostTopBarScss from "./BlogPostTopBar.scss";

var css = BlogPostTopBarScss;

function isSelectedCategory(itemName, selectedCategoryDetail) {
  if (selectedCategoryDetail !== undefined) {
    return selectedCategoryDetail.navigationName === itemName;
  } else {
    return false;
  }
}

function BlogPostTopBar(props) {
  var isIndex = props.isIndex;
  var selectedCategory = props.selectedCategory;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(Link.make, {
                                  href: Routes_BlogPost.news,
                                  className: css.linkItem,
                                  children: "All Topics"
                                }),
                            className: Cx.cx([
                                  css.linkContainer,
                                  isIndex !== undefined && isIndex ? css.underlinedLink : ""
                                ]),
                            onClick: (function (param) {
                                Url.visit(Routes_BlogPost.news);
                              })
                          }),
                      Belt_Array.mapWithIndex(props.categories, (function (index, category) {
                              return JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsx(Link.make, {
                                                href: Routes_BlogPost.category(category.slug),
                                                className: css.linkItem,
                                                children: category.title
                                              }),
                                          className: Cx.cx([
                                                css.linkContainer,
                                                category.slug === selectedCategory ? css.underlinedLink : ""
                                              ])
                                        }, category.title + String(index));
                            }))
                    ],
                    className: css.barContainer
                  }),
              className: css.barWrapper
            });
}

var make = BlogPostTopBar;

export {
  css ,
  isSelectedCategory ,
  make ,
}
/* css Not a pure module */
