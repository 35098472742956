// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../styleguide/components/Link/A.res.js";
import * as Picture from "../../../../styleguide/forms/Picture/Picture.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as LatestPosts from "../latest-posts/LatestPosts.res.js";
import * as Routes_BlogPost from "../../../../routes/common/Routes_BlogPost.res.js";
import Format from "date-fns/format";
import * as JsxRuntime from "react/jsx-runtime";
import * as CustomBlogPostSectionScss from "./CustomBlogPostSection.scss";

var css = CustomBlogPostSectionScss;

function CustomBlogPostSection(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("h2", {
                            children: props.title,
                            className: css.header
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: Belt_Array.map(props.categories, (function (category) {
                                            return JsxRuntime.jsxs("div", {
                                                        children: [
                                                          JsxRuntime.jsx("h3", {
                                                                children: category.title,
                                                                className: css.categoryTitle
                                                              }),
                                                          Belt_Array.mapWithIndex(category.blogPosts, (function (idx, post) {
                                                                  return JsxRuntime.jsxs("div", {
                                                                              children: [
                                                                                idx === 0 ? JsxRuntime.jsx(A.make, {
                                                                                        href: post.url,
                                                                                        className: css.postTitle,
                                                                                        children: JsxRuntime.jsx(Picture.make, {
                                                                                              src: post.featuredImage,
                                                                                              large: [
                                                                                                280,
                                                                                                210
                                                                                              ],
                                                                                              imageClassName: css.categoryImagePlaceholder
                                                                                            })
                                                                                      }) : null,
                                                                                JsxRuntime.jsx(A.make, {
                                                                                      href: post.url,
                                                                                      className: css.postTitle,
                                                                                      children: post.title
                                                                                    }),
                                                                                JsxRuntime.jsx("p", {
                                                                                      children: Format(post.publishedAt, "d MMM yyyy"),
                                                                                      className: css.postDate
                                                                                    })
                                                                              ]
                                                                            });
                                                                })),
                                                          JsxRuntime.jsx(A.make, {
                                                                href: Routes_BlogPost.category(category.slug),
                                                                className: css.morePosts,
                                                                children: "More News"
                                                              })
                                                        ],
                                                        className: css.categoryList
                                                      });
                                          })),
                                    className: css.categoriesList
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx(LatestPosts.make, {
                                          latestPosts: props.blogPostsTrending,
                                          className: Caml_option.some(css.trendingPosts),
                                          title: "Top Trending"
                                        }),
                                    className: css.sidebar
                                  })
                            ],
                            className: css.blogPostsContainer
                          })
                    ],
                    className: css.blogPostCategories
                  }),
              className: css.section
            });
}

var make = CustomBlogPostSection;

export {
  css ,
  make ,
}
/* css Not a pure module */
