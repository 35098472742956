// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Router from "../../../libs/Router.res.js";
import * as LinkScss from "./Link.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = LinkScss;

function Link(props) {
  var __className = props.className;
  var className = __className !== undefined ? __className : "";
  return JsxRuntime.jsx(Router.Link.make, {
              path: props.href,
              className: Cx.cx([
                    css.link,
                    className
                  ]),
              children: props.children
            });
}

var make = Link;

export {
  css ,
  make ,
}
/* css Not a pure module */
