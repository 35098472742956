// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../styleguide/components/Link/A.res.js";
import * as H1 from "../../../styleguide/components/Heading/H1.res.js";
import * as ID from "../../../libs/ID.res.js";
import * as Picture from "../../../styleguide/forms/Picture/Picture.res.js";
import * as BlogPost from "../../../models/BlogPost.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as TopServices from "../../common/top-services/TopServices.res.js";
import * as BlogNewsletter from "../common/blog-newsletter/BlogNewsletter.res.js";
import * as BlogPostTopBar from "../common/top-bar/BlogPostTopBar.res.js";
import * as Routes_BlogPost from "../../../routes/common/Routes_BlogPost.res.js";
import Format from "date-fns/format";
import * as BlogPostCategory from "../../../models/BlogPostCategory.res.js";
import * as DownloadResources from "../../resources/download-resources/DownloadResources.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as BlogPostsIndexScss from "./BlogPostsIndex.scss";
import * as CustomBlogPostSection from "../common/custom-section/CustomBlogPostSection.res.js";

var css = BlogPostsIndexScss;

function BlogPostsIndex$Component(props) {
  var categories = props.categories;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(BlogPostTopBar.make, {
                      categories: categories,
                      selectedCategory: "all",
                      isIndex: true
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(H1.make, {
                            className: css.title,
                            children: "Data Center News and Trending Topics"
                          }),
                      className: css.titleContainer
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("section", {
                                    children: JsxRuntime.jsx("div", {
                                          children: Belt_Array.map(categories, (function (category) {
                                                  return JsxRuntime.jsxs("div", {
                                                              children: [
                                                                JsxRuntime.jsx("h3", {
                                                                      children: category.title,
                                                                      className: css.categoryTitle
                                                                    }),
                                                                Belt_Array.mapWithIndex(category.blogPosts, (function (idx, post) {
                                                                        return JsxRuntime.jsxs("div", {
                                                                                    children: [
                                                                                      idx === 0 ? JsxRuntime.jsx(A.make, {
                                                                                              href: post.url,
                                                                                              className: css.postTitle,
                                                                                              children: JsxRuntime.jsx(Picture.make, {
                                                                                                    src: post.featuredImage,
                                                                                                    large: [
                                                                                                      260,
                                                                                                      210
                                                                                                    ],
                                                                                                    imageClassName: css.categoryImagePlaceholder
                                                                                                  })
                                                                                            }) : null,
                                                                                      JsxRuntime.jsx(A.make, {
                                                                                            href: post.url,
                                                                                            className: css.postTitle,
                                                                                            children: post.title
                                                                                          }),
                                                                                      JsxRuntime.jsx("p", {
                                                                                            children: Format(post.publishedAt, "d MMM yyyy"),
                                                                                            className: css.postDate
                                                                                          })
                                                                                    ]
                                                                                  }, "blog-post--" + ID.toString(post.id));
                                                                      })),
                                                                JsxRuntime.jsx(A.make, {
                                                                      href: Routes_BlogPost.category(category.slug),
                                                                      className: css.morePosts,
                                                                      children: "More News"
                                                                    })
                                                              ],
                                                              className: css.categoryList
                                                            }, "blog-post-category--" + ID.toString(category.id));
                                                })),
                                          className: css.categoriesList
                                        }),
                                    className: css.blogPosts
                                  }),
                              className: css.blogPostsContent
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(DownloadResources.make, {}),
                                JsxRuntime.jsx(TopServices.make, {})
                              ],
                              className: css.sidebar
                            })
                      ],
                      className: css.blogPostsContainer
                    }),
                JsxRuntime.jsx(CustomBlogPostSection.make, {
                      blogPostsTrending: props.blogPostsTrending,
                      categories: props.categoriesMostPopular,
                      title: "Most Popular"
                    }),
                JsxRuntime.jsx(BlogNewsletter.make, {
                      orientation: "Row",
                      borderRadius: "Flat"
                    })
              ],
              className: css.blogPostsLayout
            });
}

var Component = {
  make: BlogPostsIndex$Component
};

function BlogPostsIndex$default(props) {
  var categories = Belt_Array.map(props.categories, BlogPostCategory.Section.fromJs);
  var categoriesMostPopular = Belt_Array.map(props.categoriesMostPopular, BlogPostCategory.Section.fromJs);
  var blogPostsTrending = Belt_Array.map(props.blogPostsTrending, BlogPost.Simple.fromJs);
  return JsxRuntime.jsx(BlogPostsIndex$Component, {
              categories: categories,
              categoriesMostPopular: categoriesMostPopular,
              blogPostsTrending: blogPostsTrending
            });
}

var make = BlogPostsIndex$Component;

var $$default = BlogPostsIndex$default;

export {
  css ,
  Component ,
  make ,
  $$default as default,
}
/* css Not a pure module */
