// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as $$Date from "../libs/Date.res.js";
import * as BlogPost from "./BlogPost.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromJs(js) {
  return {
          id: js.id,
          title: js.title,
          slug: js.slug
        };
}

var $$Navigation = {
  fromJs: fromJs
};

function fromJs$1(js) {
  return {
          id: js.id,
          title: js.title,
          slug: js.slug,
          featured: js.featured,
          protected: js.protected,
          blogPosts: js.blogPosts,
          priorityOrder: js.priorityOrder,
          createdAt: $$Date.Naive.fromString(js.createdAt),
          updatedAt: $$Date.Naive.fromString(js.updatedAt)
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              title: field.required("title", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              featured: field.required("featured", Json_Decode$JsonCombinators.bool),
              protected: field.required("protected", Json_Decode$JsonCombinators.bool),
              blogPosts: field.required("blogPosts", Json_Decode$JsonCombinators.$$int),
              priorityOrder: field.required("priorityOrder", Json_Decode$JsonCombinators.$$int),
              createdAt: field.required("createdAt", $$Date.Naive.decoder),
              updatedAt: field.required("updatedAt", $$Date.Naive.decoder)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

var Dashboard = {
  fromJs: fromJs$1,
  decoder: decoder,
  fromJson: fromJson
};

function fromJs$2(js) {
  return {
          id: js.id,
          title: js.title,
          description: js.description,
          metaTitle: js.metaTitle,
          metaDescription: js.metaDescription,
          featured: js.featured,
          priorityOrder: js.priorityOrder,
          createdAt: $$Date.Naive.fromString(js.createdAt),
          updatedAt: $$Date.Naive.fromString(js.updatedAt)
        };
}

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              title: field.required("title", Json_Decode$JsonCombinators.string),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              metaTitle: field.required("metaTitle", Json_Decode$JsonCombinators.string),
              metaDescription: field.required("metaDescription", Json_Decode$JsonCombinators.string),
              featured: field.required("featured", Json_Decode$JsonCombinators.bool),
              priorityOrder: field.required("priorityOrder", Json_Decode$JsonCombinators.$$int),
              createdAt: field.required("createdAt", $$Date.Naive.decoder),
              updatedAt: field.required("updatedAt", $$Date.Naive.decoder)
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

var Edit = {
  fromJs: fromJs$2,
  decoder: decoder$1,
  fromJson: fromJson$1
};

function fromJs$3(js) {
  return {
          id: js.id,
          title: js.title
        };
}

var decoder$2 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              title: field.required("title", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$2(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$2));
}

var Filter = {
  fromJs: fromJs$3,
  decoder: decoder$2,
  fromJson: fromJson$2
};

function fromJs$4(js) {
  return {
          id: js.id,
          title: js.title,
          slug: js.slug,
          blogPosts: Belt_Array.map(js.blogPosts, BlogPost.Simple.fromJs)
        };
}

var Section = {
  fromJs: fromJs$4
};

export {
  $$Navigation ,
  Dashboard ,
  Edit ,
  Filter ,
  Section ,
}
/* decoder Not a pure module */
